import * as Types from '../Constants/AllConstants'

// get all categories

export const getCategories = (
    state = {
        categories:[],
    },
    action
) => {
    switch (action.type) {
        case Types.CATEGORY_LIST_REQUEST:
            return { loading: true };
        case Types.CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload};
        case Types.CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload};
        case Types.CATEGORY_LIST_RESET:
            return { categories: [] };
        default:
            return state;
    }
};

// GET CATEGORY BY ID reducer
export const getCategoryByIdReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.CATEGORY_GET_BY_ID_REQUEST:
            return { loading: true};
        case Types.CATEGORY_GET_BY_ID_SUCCESS:
            return { loading: false, category: action.payload};     
        case Types.CATEGORY_GET_BY_ID_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.CATEGORY_GET_BY_ID_RESET:
            return { category: [] };       
        default:
            return state;
    }
}

// UPDATE category by id reducer
export const updateCategoryReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.CATEGORY_UPDATE_REQUEST:
            return { loading: true};
        case Types.CATEGORY_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.CATEGORY_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// DELETE category by id reducer
export const deleteCategoryReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.CATEGORY_DELETE_REQUEST:
            return { loading: true};
        case Types.CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.CATEGORY_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  

// create category reducer
export const createCategoryReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.CATEGORY_CREATE_REQUEST:
            return { loading: true};
        case Types.CATEGORY_CREATE_SUCCESS:
            return { loading: false, success: true, category: action.payload };     
        case Types.CATEGORY_CREATE_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.CATEGORY_CREATE_RESET:
            return { };       
        default:
            return state;
    }
}