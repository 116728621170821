import * as Types from '../Constants/AllConstants';

export const LoginReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.USER_LOGIN_REQUEST:
            return { loading: true};
        case Types.USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload};
        case Types.USER_LOGIN_FAIL:
            return { loading: false, error: action.payload};
        case Types.USER_LOGIN_RESET || Types.USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const RegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.USER_REGISTER_REQUEST:
            return { loading: true};
        case Types.USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload};
        case Types.USER_REGISTER_FAIL:
            return { loading: false, error: action.payload};
        case Types.USER_REGISTER_RESET:
            return {};
        default:
            return state;
    }
}

export const UpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.PROFILE_UPDATE_REQUEST:
            return { loading: true};
        case Types.PROFILE_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.PROFILE_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.PROFILE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

export const UpdateAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.ADDRESS_UPDATE_REQUEST:
            return { loading: true};
        case Types.ADDRESS_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.ADDRESS_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.ADDRESS_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

export const DeleteAddressByIdReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.ADDRESS_DELETE_BY_ID_REQUEST:
            return { loading: true};
        case Types.ADDRESS_DELETE_BY_ID_SUCCESS:
            return { loading: false, success: true};
        case Types.ADDRESS_DELETE_BY_ID_FAIL:
            return { loading: false, error: action.payload};
        case Types.ADDRESS_DELETE_BY_ID_RESET:
            return {};
        default:
            return state;
    }
}

export const UpdateAddressByIdReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.ADDRESS_UPDATE_BY_ID_REQUEST:
            return { loading: true};
        case Types.ADDRESS_UPDATE_BY_ID_SUCCESS:
            return { loading: false, success: true};
        case Types.ADDRESS_UPDATE_BY_ID_FAIL:
            return { loading: false, error: action.payload};
        case Types.ADDRESS_UPDATE_BY_ID_RESET:
            return {};
        default:
            return state;
    }
}

export const ChangePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.PASSWORD_CHANGE_REQUEST:
            return { loading: true};
        case Types.PASSWORD_CHANGE_SUCCESS:
            return { loading: false, success: true};
        case Types.PASSWORD_CHANGE_FAIL:
            return { loading: false, error: action.payload};
        case Types.PASSWORD_CHANGE_RESET:
            return {};
        default:
            return state;
    }
}

export const DeleteAccountReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.ACCOUNT_DELETE_REQUEST:
            return { loading: true};
        case Types.ACCOUNT_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.ACCOUNT_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.ACCOUNT_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  

// get all users reducer
export const getAllUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case Types.USER_GET_ALL_REQUEST:
            return { loading: true};
        case Types.USER_GET_ALL_SUCCESS:
            return { 
                loading: false, 
                users: action.payload,
            };     
        case Types.USER_GET_ALL_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.USER_GET_ALL_RESET:
            return { users: [] };       
        default:
            return state;
    }
}

// GET USER BY ID reducer
export const getUserByIdReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case Types.USER_GET_BY_ID_REQUEST:
            return { loading: true};
        case Types.USER_GET_BY_ID_SUCCESS:
            return { loading: false, user: action.payload};     
        case Types.USER_GET_BY_ID_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.USER_GET_BY_ID_RESET:
            return { user: [] };       
        default:
            return state;
    }
}


// UPDATE user by id reducer
export const updateUserReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.USER_UPDATE_REQUEST:
            return { loading: true};
        case Types.USER_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.USER_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.USER_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// DELETE user by id reducer
export const deleteUserReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case Types.USER_DELETE_REQUEST:
            return { loading: true};
        case Types.USER_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.USER_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.USER_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  
