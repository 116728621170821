import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PopUpProvider } from './Context/PopUpContex';
import ToastContainer from './components/Notifications/Toaster';
import ScrollToTop from './ScrollOnTop';
import BigLoader from './components/Notifications/BigLoader';
import { ProtectedRouter } from './ProtectedRouter';
import { ProtectedAdminRouter } from './ProtectedAdminRouter'

const HomeScreen = React.lazy(() => import('./screens/HomeScreen'));
const AboutUs = React.lazy(() => import('./screens/AboutUs'));
const ContactUs = React.lazy(() => import('./screens/ContactUs'));
const Shop = React.lazy(() => import('./screens/Shop'));
const Community = React.lazy(() => import('./screens/Community'));
const FAQPage = React.lazy(() => import('./screens/FAQ'));
const PrivacyPolicy = React.lazy(() => import('./screens/Privacy'));
const TermAndConditions = React.lazy(() => import('./screens/TermsCondition'));
const OrderScreen = React.lazy(() => import('./screens/OrderScreen'));
const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));
const Orders = React.lazy(() => import('./screens/Dashboard/Orders'));
const UpdateProfile = React.lazy(() =>
  import('./screens/Dashboard/UpdateProfile')
);
const Address = React.lazy(() =>
  import('./screens/Dashboard/Address')
);
const Password = React.lazy(() => import('./screens/Dashboard/Password'));
const RegistationScreen = React.lazy(() =>
  import('./screens/Dashboard/RegistationScreen')
);
const NotFound = React.lazy(() => import('./screens/NotFound'));
const ProductDetails = React.lazy(() => import('./screens/ProductDetails'));

const LoginAdmin = React.lazy(() => import('./screens/AdminDashboard/LoginAdmin'));
const OrdersAdmin = React.lazy(() => import('./screens/AdminDashboard/OrdersAdmin'));
const CategoriesAdmin = React.lazy(() => import('./screens/AdminDashboard/CategoriesAdmin'));
const ProductsAdmin = React.lazy(() => import('./screens/AdminDashboard/ProductsAdmin'));
const UsersAdmin = React.lazy(() => import('./screens/AdminDashboard/UsersAdmin'));
const OrderInfo = React.lazy(() => import('./screens/OrderInfo'));
const ProductInfo = React.lazy(() => import('./screens/AdminDashboard/ProductInfo'));
const CategoryInfo = React.lazy(() => import('./screens/AdminDashboard/CategoryInfo'));
const UserInfo = React.lazy(() => import('./screens/AdminDashboard/UserInfo'));
const TournamentInfo = React.lazy(() => import('./screens/AdminDashboard/TournamentInfo'));
const NewProductAdmin = React.lazy(() => import('./screens/AdminDashboard/NewProductAdmin'));
const NewCategoryAdmin = React.lazy(() => import('./screens/AdminDashboard/NewCategoryAdmin'));
const TournamentsAdmin = React.lazy(() => import('./screens/AdminDashboard/TournamentsAdmin'));
const NewTournamentAdmin = React.lazy(() => import('./screens/AdminDashboard/NewTournamentAdmin'));




function App() {
  AOS.init();
  return (
    <PopUpProvider>
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          {/* PROTECTED */}
          <Route element={<ProtectedRouter/>}>
            <Route
              path="/order/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <OrderScreen />
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/orders"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Orders />
                </Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <Suspense fallback={<BigLoader />}>
                  <UpdateProfile />
                </Suspense>
              }
            />
            <Route
              path="/address"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Address />
                </Suspense>
              }
            />
            <Route
              path="/password"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Password />
                </Suspense>
              }
            />
          </Route>

          {/* PUBLIC */}
          <Route
            path="/"
            element={
              <Suspense fallback={<BigLoader />}>
                <HomeScreen />
              </Suspense>
            }
          />
          <Route
            path="/card/:id"
            element={
              <Suspense fallback={<BigLoader />}>
                <ProductDetails />
              </Suspense>
            }
          />
          <Route
            path="/registation"
            element={
              <Suspense fallback={<BigLoader />}>
                <RegistationScreen />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<BigLoader />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<BigLoader />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path="/shop"
            element={
              <Suspense fallback={<BigLoader />}>
                <Shop />
              </Suspense>
            }
          />
            <Route
            path="/community"
            element={
              <Suspense fallback={<BigLoader />}>
                <Community />
              </Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <Suspense fallback={<BigLoader />}>
                <FAQPage />
              </Suspense>
            }
          />
          <Route
            path="/terms-condition"
            element={
              <Suspense fallback={<BigLoader />}>
                <TermAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/policy"
            element={
              <Suspense fallback={<BigLoader />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<BigLoader />}>
                <NotFound />
              </Suspense>
            }
          />

          {/* PROTECTED ADMIN ZONE */}
           <Route
            path="/loginadmin"
            element={
              <Suspense fallback={<BigLoader />}>
                <LoginAdmin />
              </Suspense>
            }
          />

          <Route element={<ProtectedAdminRouter/>}>
           <Route
              path="/ordersadmin"
              element={
                <Suspense fallback={<BigLoader />}>
                  <OrdersAdmin />
                </Suspense>
              }
            />
            <Route
              path="/categoriesadmin"
              element={
                <Suspense fallback={<BigLoader />}>
                  <CategoriesAdmin />
                </Suspense>
              }
            />
            <Route
              path="/productsadmin"
              element={
                <Suspense fallback={<BigLoader />}>
                  <ProductsAdmin />
                </Suspense>
              }
            />
            <Route
              path="/usersadmin"
              element={
                <Suspense fallback={<BigLoader />}>
                  <UsersAdmin />
                </Suspense>
              }
            />
            <Route
              path="/tournamentsadmin/"
              element={
                <Suspense fallback={<BigLoader />}>
                  <TournamentsAdmin/>
                </Suspense>
              }
            />
            <Route
              path="/newproductadmin/"
              element={
                <Suspense fallback={<BigLoader />}>
                  <NewProductAdmin/>
                </Suspense>
              }
            />
            <Route
              path="/newcategoryadmin/"
              element={
                <Suspense fallback={<BigLoader />}>
                  <NewCategoryAdmin/>
                </Suspense>
              }
            />
            <Route
              path="/newtournamentadmin/"
              element={
                <Suspense fallback={<BigLoader />}>
                  <NewTournamentAdmin/>
                </Suspense>
              }
            />
            <Route
              path="/orderinfo/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <OrderInfo />
                </Suspense>
              }
            />
            <Route
              path="/productinfo/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <ProductInfo />
                </Suspense>
              }
            />
             <Route
              path="/categoryinfo/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <CategoryInfo />
                </Suspense>
              }
            />
            <Route
              path="/userinfo/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <UserInfo/>
                </Suspense>
              }
            />
            <Route
              path="/tournamentinfo/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <TournamentInfo/>
                </Suspense>
              }
            />
            </Route>
        </Routes>
      </ScrollToTop>
    </PopUpProvider>
  );
}

export default App;
